import React from "react";


export default function webinar() {

    return(

        <>
        <div className="container-custom">
      

         <div className="mt-2 pt-2 px-0 px-md-5 px-lg-5 px-xl-5">
         <h1 className="heading my-3"> Webinars </h1>
         <h2 className="heading my-3">Past Webinar </h2>
            <div className="row align-items-center">
              <div className="col-md-6 mb-4 order-last order-sm-last order-md-first order-lg-first order-xxl-first">
                <h4 className="common-heading my-3"> WEBINAR 1 Waste Management & Circular Economy</h4>

                <div align="justify" className="comman-para ">
                The inaugural IESGN Connect Webinar, held in collaboration with Karo Sambhav, marked a significant milestone in the journey towards promoting sustainability and environmental stewardship. Led by the esteemed speaker Ms. Sreepriya Sridharan, 
                the webinar focused on the crucial topics of Waste Management and Circular Economy. Through her expertise and insights, Ms. Sreedharan illuminated key strategies and innovative approaches to address 
                the pressing challenges of waste management while embracing the principles of the circular economy. Attendees were enlightened on the importance of adopting sustainable practices and exploring opportunities for waste reduction, reuse, and recycling across various sectors.
              
                  <br /> This webinar served as an inspirational launchpad for future engagements, setting the stage for collaborative efforts and impactful initiatives aimed at fostering a more sustainable and resilient future.

                </div>
              </div>
              <div className="col-md-6">
                <iframe
                  width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/OwayCkViFOI?si=fRxQ7iubdFmy5Tof"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
               <br></br>
               
               <div className="row align-items-center">
           
              <div className="col-md-6 mb-4 order-last order-sm-last order-md-first order-lg-first order-xxl-first">
            
          
                <iframe
                  width="100%"
                  height="350"
                  src="https://www.youtube.com/embed/N0CqG69Oy_8?si=Ry95-rGgwKlgas3t"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              
               
              </div>

              <div className="col-md-6">
              <h4 className="common-heading mb-3"> WEBINAR 2 Building an Ecosystem to deliver circular Economy in Automotive Industry.</h4>
              <div align="justify" className="comman-para">
                In a highly anticipated session of the IESGN Connect Webinar series, Mr. Prabhat Khare, a seasoned expert with more than 25 years of experience, took the virtual stage to share his insights on "Building an Ecosystem to Deliver Circular Economy Practices in the Automotive Industry." 
                With a profound understanding of both the automotive sector and circular economy principles, Mr. Khare delivered a compelling presentation that captivated attendees. 
                He delved into the intricate dynamics of creating a sustainable ecosystem within the automotive industry, emphasizing the importance of collaboration, innovation, and stakeholder engagement. 
                Drawing from his extensive experience, Mr. Khare outlined practical strategies and actionable steps to integrate circular economy practices into automotive manufacturing, supply chains, and product lifecycles.
                
                  <br />Attendees left the webinar inspired and empowered, armed with newfound knowledge and a renewed commitment to driving positive change towards a more sustainable future in the automotive sector.  

                </div>
                </div>
            
            </div>
          </div>




        </div>
        </>
    );
} 